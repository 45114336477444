import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

export function createScene( mount,isMobile ){
	
    const width = mount.clientWidth;
    const height = mount.clientHeight;

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
        30, // fov = field of view
        width / height, // aspect ratio
        0.1, // near plane
        60000 // far plane
    );
    
    camera.zoom = camera.aspect * ( isMobile ? 1.2 : .75 ) ;
    camera.updateProjectionMatrix();
    
    
   const controls = new OrbitControls( camera, mount );
	controls.enableZoom = false;
	// 	controls.enablePan = false;
	// 	controls.enableRotation = false;
	controls.noPan = true; 
	controls.noKeys = true;
	controls.noRotate = true;
	controls.noZoom = true;
    controls.update();
    
    return [ scene, camera, controls ];
    
};
	
export default createScene;