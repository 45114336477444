import React, { Component } from "react";
import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min.js';
import { convertColor, getLum } from './Misc/Utilities';

export class Label extends Component {

	constructor(props){
		super(props)
		this.state = {
			sideSelected: 'left',
			checkboxes: [
				{
					name: 'Engaged',
					active: false
				},
				{
					name: 'Excited',
					active: false
				},
				{
					name: 'Thrilled',
					active: false
				},
				{
					name: 'Intrigued',
					active: false
				},
				{
					name: 'Happy',
					active: false
				},
				{
					name: 'Motivated',
					active: false
				},
				{
					name: 'Eager to learn more',
					active: false
				}
			]
	 	}
	 	this.cssScene = typeof this.props.cssScene;
	 	this.handleClick = this.handleClick.bind(this);
	 	this.handleHover = this.handleHover.bind(this);
	}

	componentDidMount() {

		const labelCSS = new CSS3DObject( document.getElementById('label_'+this.props.keyID) );
		labelCSS.position.x = this.props.labelData.initPosition.x;
		labelCSS.position.y = this.props.labelData.initPosition.y;
		labelCSS.position.z = this.props.labelData.initPosition.z;
		labelCSS.scale.set(.1,.1);

		// ONLY FOR NEW TOUCH POINTS

		const labelContent = document.getElementById('label_'+this.props.keyID).getElementsByClassName("label")[0];

		if (this.props.cssScene){

			this.props.cssScene.add( labelCSS );
			setTimeout(()=>{
				labelContent.style.opacity = '1';
				labelContent.style.animation = 'none';
			}, 700)
		}

		this.props.labelData.labelCSS = labelCSS;

		// BUTTON

		if (this.props.labelData.buttonText !== ''){

			const labelButtonCSS = new CSS3DObject(  document.getElementById('button_'+this.props.keyID) );
			labelButtonCSS.position.x = this.props.labelData.initPosition.x;
			labelButtonCSS.position.y = this.props.labelData.initPosition.y;
			labelButtonCSS.position.z = this.props.labelData.initPosition.z + 10;
			labelButtonCSS.scale.set(.1,.1);

			// ADD TO LABEL OBJECT

			if (this.props.cssScene) // ONLY RUN IF CSSSCENE IS CREATED
			this.props.cssScene.add( labelButtonCSS );

			this.props.labelData.labelButtonCSS = labelButtonCSS;

		}

		this.floatLabel();

	}

	floatLabel = () => {

		const labelCSS = this.props.labelData.labelCSS;
		const initPosition = this.props.labelData.initPosition;

		const max = 3;
		const min = -3;
		const locX = initPosition.x + ( !this.props.labelData.labelActive ? Math.random() * (max - min) + min : 0 );
		const locY = initPosition.y + ( !this.props.labelData.labelActive ? Math.random() * (max - min) + min : 0 );
		const duration = ( !this.props.labelData.labelActive ? Math.random() * 2000 + 2600 : 2300 );

		let position = { x : labelCSS.position.x, y: initPosition.y };
		let target = { x : locX, y : locY };

		this.props.labelData.floatLabel = null;
		this.props.labelData.floatLabel = new TWEEN.Tween( labelCSS.position )
			.to( target, duration )
			.easing( TWEEN.Easing.Quadratic.InOut )
			.onUpdate( () => {
				if (this.props.labelData.labelActive){
					this.props.labelData.floatLabel.stop();
					this.floatLabel();
				}
			})
			.onComplete( () => {
				this.floatLabel();
			})
			.start();
	};

	removeLabel = ( keyID ) => {

		// GRAB DATA AS STATE WILL REMOVE LABEL BUT STILL NEED DATA TO DELETE BELOW

		const tempLabel = this.props.labelData;

		document.getElementById('label_'+this.props.keyID).classList.add('close')

// 		this.props.removeLabel( keyID );

		setTimeout( () => {

			// REMOVE FROM HTML

			this.props.cssScene.remove( tempLabel.labelCSS )
			if (tempLabel.labelButtonCSS)
			this.props.cssScene.remove( tempLabel.labelButtonCSS )

		}, 500);

	}

	/* LABEL CLICK */

	handleClick = ( label ) => {

		clearInterval(this.intervalRewind);

		if (!!label.labelActive)
		return;

		// SET ACTIVE STATE - UPDATE GLOBAL STATE

		this.props.updateActiveLabel( label );

		// MOVE BUTTON

		if (this.props.labelData.labelButtonCSS){
			setTimeout(() => {
				const labelHeight = label.labelCSS.element.getElementsByClassName('label')[0].clientHeight;
// 				console.log(labelHeight)
				label.labelButtonCSS.element.style.top = '-' + (labelHeight/(this.props.isMobile ? 10.9 : 10) - ((700 - labelHeight)/120) ) + 'px';
				label.labelButtonCSS.element.style.visibility = 'visible';
			},1000);
		}


	    // GET POSITION OF LABEL

		let targetPosition = [];
		targetPosition.x = label.initPosition.x * ( this.props.isMobile ? 1.2 : 1.5 ) // MOVE CAM BEYOND LABEL SO LOOKS BACK AT ANGLE
			- ( this.props.camPositions.[this.props.camCurrentPosition].position.x / 3 ) // IF DEVICE STARTS ON SIDE, KEEP CALLOUTS ON SIDE
			* ( Math.sign(label.initPosition.x) === -1 && this.props.camCurrentPosition !== 'center' ? .3 : 1 ); // ADJUST ANGLE OF CAMERA DEPENDING ON WHICH SIDE CALLOUT IS ON

		targetPosition.y = label.initPosition.y - 23 // MOVE CALLOUT POSITION UP
			- Math.sign(label.initPosition.y) * (label.initPosition.y / 10) //  // MOVE CALLOUT POSITION UP
			- ( this.props.camPositions.[this.props.camCurrentPosition].position.y / 3 ); // ADD Y OFFSET
		targetPosition.z = label.initPosition.z + 330 // ZOOM IN WITH OFFEST TO LABEL
			- ( this.props.isMobile ? 130 : 0 ) // IF MOBILE, MOVE CLOSER;

		let targetLookPosition = [];
		targetLookPosition.x = label.initPosition.x * ( this.props.isMobile ? .95 : .7 ); // CENTER
		if (this.props.camCurrentPosition !== 'center')
		targetLookPosition.x = label.initPosition.x - ( this.props.camPositions.[this.props.camCurrentPosition].position.x * .25 * ( Math.sign(label.initPosition.x) === -1 ? .6 : 1 ) ) - (label.initPosition.x / 5);
		targetLookPosition.y = label.initPosition.y - 30; // Add offset so camera aims down toward label
		targetLookPosition.z = 0;

	    // PING WEBFLOW TO UPDATE

	    if (!this.props.editMode && !this.props.theaterMode){
	    	window.parent.postMessage('on_'+this.props.isMobile,"*");
		    window.parent.postMessage('label_on'+(this.props.isMobile ? '_mobile' : ''),"*");
	    }

		// MOVE CAMERA

	    this.props.moveCamera( targetPosition,targetLookPosition )


		// PLAY VIDEO

/*
		let playDirection = state === 'open' ? 'forward' : 'backward';
		if (!!this.state.media.play)
		this.playVideo( playDirection );
*/
	}


	handleHover = ( label,action ) => {

		if (label.labelActive || this.props.isMobile)
		return;

		let target = { x : 0, y : 0, z: (action === 'enter' ? -2 : 2) };
		let position = { x : 0, y: 0, z: 0 };

		if (action === 'enter')
		this.props.labelData.floatLabel.stop();
		if (action === 'leave')
		this.props.labelData.floatLabel.start();

		new TWEEN.Tween( position )
			.to( { z: target.z }, 400 )
			.easing( TWEEN.Easing.Quadratic.Out )
			.onUpdate(function(){
				label.labelCSS.position.z = label.initPosition.z + position.z;
			})
			.start();

	}

	handleButtonHover = ( label,action ) => {

		if (this.props.isMobile)
		return;

		let target = { x : 0, y : 0, z: (action === 'enter' ? -1 : 1) };
		let position = { x : 0, y: 0, z: 0 };

		new TWEEN.Tween( position )
			.to( { z: target.z }, 300 )
			.easing( TWEEN.Easing.Quartic.Out )
			.onUpdate(function(){
				label.labelButtonCSS.position.z = label.initPosition.z + 8 + position.z;
			})
			.start();

	}

	handleButtonClick = ( label ) => {

	    // PING WEBFLOW TO UPDATE
	    const message = 'labelClick';
	    window.parent.postMessage( message,"*" );
		
	}

	handleCheckboxToggle = ( key ) => {
		let array = [...this.state.checkboxes];
		array[key].active = !array[key].active;

        this.setState(state => ({
            checkboxes: array
        }));
	}

	getTitleOpacity = () => {
		const titleOpacity = getLum(this.props.skyColor);
		return (titleOpacity > 150) ? '.4' : '.8';
	}

    render() {

        return (
	        <div>
		        <div key={ this.props.keyID } className={ "labelContainer " + ( this.props.labelData.labelActive ? 'open' : '' ) + " "  + ( this.props.labelData.labelClose ? 'close' : '' ) } id={ "label_" + this.props.keyID } >

		        	{ this.props.keyID === 3 &&
					<div className={"removeLabel editClose " + ( !this.props.editMode ? 'hide' : '' ) } onClick={() => this.removeLabel( this.props.keyID ) }/>
					}

			        <div
			        	className="label "
			        	style={{
				        	'cursor':'pointer',
				        	'animationDelay': ( this.cssScene !== 'undefined' ? '0s' : ( this.props.keyID * .2) + 's' ),
				        	"backgroundImage": "linear-gradient(45deg, rgba("+convertColor(this.props.skyColor)+", 0.1) 0%, rgba("+convertColor(this.props.groundColor)+", .05) 40%, rgba("+convertColor(this.props.groundColor)+", .02) 100%)",
				        	"borderLeft": "rgba("+convertColor(this.props.skyColor)+", 0.2) solid 2px",
				        	"borderBottom": "rgba("+convertColor(this.props.groundColor)+", 0.2) solid 2px",
				        	 }}
			        >
						<div className="labelTitle"
				        	onClick={() => this.handleClick( this.props.labelData )}
				        	onTouchStart={() => this.handleClick( this.props.labelData )}
				        	onMouseLeave={() => this.handleHover( this.props.labelData,'leave' )}
				        	onMouseEnter={() => this.handleHover( this.props.labelData,'enter' )}>
							<span
								style={{ "backgroundImage": "-webkit-linear-gradient(45deg, rgba("+convertColor(this.props.skyColor)+", "+this.getTitleOpacity()+"), rgba("+convertColor(this.props.groundColor)+", "+this.getTitleOpacity()+"))",
									"WebkitBackgroundClip": "text",
									"backgroundColor": "#000" }}
							>
								{ this.props.labelData.title }
							</span>
						</div>

						{ this.props.labelData.style === 'split' &&

							<div className={"labelLayout labelContent " + this.state.sideSelected}>
							 	<div className="labelTabs">
								    <div onClick={() => this.setState({ sideSelected: "left" })} onTouchStart={() => this.setState({ sideSelected: "left" })} >Point → Click → Value It's that simple!</div>
								    <div onClick={() => this.setState({ sideSelected: "right" })} onTouchStart={() => this.setState({ sideSelected: "right" })} >Resonate with multiple audiences</div>
								</div>
							    <div className={"labelSplit " + (this.props.labelData.buttonText !== '' ? 'buttonPad' : '') }>
							        <div className="labelSplit-inner">
									    <div className="labelSplit-block">
										    <div className="labelGraphic" style={{backgroundImage: `url("` + process.env.PUBLIC_URL + `/assets/landing_page/value2.png")`}}>
										    { /*
											    <video autoPlay="autoplay" muted loop="loop" muted="muted" playsInline data-object-fit="cover" data-wf-ignore="true">
										    		<source src={ process.env.PUBLIC_URL + '/assets/landing_page/value.mov' } data-wf-ignore="true" />
										    	</video>
										    	*/
										    }
										    </div>
											<div dangerouslySetInnerHTML={{ __html: this.props.labelData.text01 }} />
									    </div>
									    <div className="labelSplit-block">
										    <div className="labelGraphic" style={{backgroundImage: `url("` + process.env.PUBLIC_URL + `/assets/landing_page/sample.png")`}} />
											<div dangerouslySetInnerHTML={{ __html: this.props.labelData.text02 }} />
									    </div>
								    </div>
								</div>
							</div>

						}

						{ this.props.labelData.style === 'checkbox' &&

					    	<div className="labelContent">
							    <div className={"labelCheckbox " + (this.props.labelData.buttonText !== '' ? 'buttonPad' : '') }>
									<div dangerouslySetInnerHTML={{ __html: this.props.labelData.text01 }} />
								    <div className="checkboxContainer">

								    {Object.entries(this.state.checkboxes).map(([key, item]) => (
									   <div key={key} className={"checkbox " + ("color" + key%3) + (item.active ? ' on' : '')} onClick={ () =>  this.handleCheckboxToggle(key) }>{item.name}</div>
									))}

								    </div>
									<div dangerouslySetInnerHTML={{ __html: this.props.labelData.text02 }} />
								</div>
							</div>

						}

						{ this.props.labelData.style === 'article' &&

							<div className="labelContent">
							    <div className={"labelArticle " + (this.props.labelData.buttonText !== '' ? 'buttonPad' : '') }>
							    	{ this.props.labelData.imageURL !== "" &&
								    	<img src={ process.env.PUBLIC_URL + this.props.labelData.imageURL } alt="labelImg"  className="labelImg" />
								    }
									<div dangerouslySetInnerHTML={{ __html: this.props.labelData.text01 }} />
								</div>
							</div>

						}

					</div>
				</div>

				{ this.props.labelData.buttonText !== '' &&

					<div className="labelButtonContainer" id={ "button_" + this.props.keyID }>
						<div className={"labelButton " + ( this.props.labelData.labelActive ? 'open' : '' )}
				        	onMouseLeave={() => this.handleButtonHover( this.props.labelData,'leave' )}
				        	onMouseEnter={() => this.handleButtonHover( this.props.labelData,'enter' )}
				        	onClick={() => this.handleButtonClick( this.props.labelData )}>
							{ this.props.labelData.buttonText }
						</div>
					</div>

				}
			</div>

	    )
	}
}
