import * as THREE from "three";

export function createLights( scene, skyColor, groundColor ){

    const pointLight = new THREE.PointLight( 0xffffff, 0 );
    pointLight.position.set( 50, 100, 100 );

    scene.add( pointLight );
    
    
    
	const hemiLight = new THREE.HemisphereLight( parseInt('0x' + groundColor.replace('#','') ,16), parseInt('0x' + skyColor.replace('#','') ,16), 0 );
	hemiLight.position.set( 0,1,.2 );
	hemiLight.name = "hemiLight";
	scene.add( hemiLight );


    
	const aoLight = new THREE.AmbientLight( 0xFFFFFF , .4); // soft white light
	aoLight.name = "aoLight";
//  	scene.add( aoLight );


    
    const shadowLight = new THREE.DirectionalLight( 0xffffff, .5);
	shadowLight.position.set( 0, 100, 20 );
	shadowLight.target.position.set( -5, 90, -10 );
	shadowLight.penumbra = 0;
	shadowLight.decay = 0;
	shadowLight.distance = 100;
	shadowLight.name = "shadowLight";

	shadowLight.castShadow = true;
	shadowLight.shadow.mapSize.width = 4048;
	shadowLight.shadow.mapSize.height = 4048;
	shadowLight.shadow.camera.left = -500;
	shadowLight.shadow.camera.right = 500;
	shadowLight.shadow.camera.top = 500;
	shadowLight.shadow.camera.bottom = -500;
	shadowLight.shadow.camera.near = 1;
	shadowLight.shadow.camera.far = 100;

	shadowLight.name = "shadowLight";

	return [ hemiLight,pointLight ] ;

};
	
export default createLights;