import React, { Component } from "react";
import Viewport from './Viewport';

/* LOAD FULL APP */

class App extends Component {
  render() {
    return (
		    <Viewport />
	    );
  }
}

export default App;

