import React, { Component } from "react";
import * as THREE from "three";
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min.js';
import Stats from 'three/examples/jsm/libs/stats.module';
import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';

// INITIALIZE
import { createScene } from './Initialize/createScene';
import { createCSSScene } from './Initialize/createCSSScene';
import { createRenderer } from './Initialize/createRenderer';
import { createLights } from './Initialize/createLights';
import { createDevice } from './Initialize/createDevice';
import { createMedia } from './Initialize/createMedia';
import { createSpheres, updateSpheres } from './Initialize/createSpheres';
import { Label } from './Label';
// import { createBG } from './Initialize/createBG';

// EDIT
import EditPoints from './Editor/EditPoints';

// MISC
import SvgContainer from './Misc/svgContainer';
import { hexToRgb } from './Misc/Utilities';
import { Dropzone } from './Misc/Dropzone';


class App extends Component {

	constructor(props){
		super(props)
		this.state = {
			isMounted: true,
			progressPct: 0,
			globalLabelActive: false,
			labelObjects: {},
			mouse: { x: 0, y: 0 },
			background: "purple",
			backgrounds: {
				purple: {
					bg: "radial-gradient(circle farthest-side at -20% 0%, rgba(255, 255, 255, 0.8) 0%,rgba(255, 255, 255, 0.2) 40%, rgba(255, 255, 255, 0) 60%),linear-gradient( -1300deg, #401fd6, #be43e3 60%, #e448d2 80%)",
					skyColor: "#4f27ff",
					groundColor: "#f420dc",
					sphereRight_skyColor: "#c564f6",
					sphereRight_groundColor: "#b244af",
					sphereLeft_skyColor: "#b9adf1",
					sphereLeft_groundColor: "#8d44cc"
				},
				white: {
					bg: "linear-gradient(180deg, #fafbfb 0%, #dbdfe0 100%)",
					skyColor: "#fafbfb",
					groundColor: "#888888",
					sphereRight_skyColor: "#fafbfb",
					sphereRight_groundColor: "#888888",
					sphereLeft_skyColor: "#fafbfb",
					sphereLeft_groundColor: "#888888"
				},
				dark_grey: {
					bg: "linear-gradient( 180deg,#1b1e22 0%, #131417 100%)",
					skyColor: "#1b1e22",
					groundColor: "#131417",
					sphereRight_skyColor: "#35383a",
					sphereRight_groundColor: "#131417",
					sphereLeft_skyColor: "#242628",
					sphereLeft_groundColor: "#0d0e10"
				},
				rose: {
					bg: "linear-gradient( 180deg,#ca5a80 0%, #8f2848 100%)",
					skyColor: "#bd5c7d",
					groundColor: "#90314f",
					sphereRight_skyColor: "#e6759c",
					sphereRight_groundColor: "#7b2842",
					sphereLeft_skyColor: "#bd5c7d",
					sphereLeft_groundColor: "#76243e"
				}
			},
			device: {
				name: "monitor",
				color: "silver"
			},
			devices: {
				monitor: {
					colors: {
						grey: "#000000",
						silver: "#dddddd"
					}
				}
			},
			camPositions: {
				right : {
					position: { x: 150, y: -10, z: 620 },
					lookAt: { x: -80, y: -20, z: 0 },
				},
				center : {
					position: { x: 0, y: -10, z: 560 },
					lookAt: { x: 0, y: -25, z: 0 },
				},
				left : {
					position: { x: -150, y: -10, z: 620 },
					lookAt: { x: 80, y: -20, z: 0 },
				}
			},
			camLocation: {
				position: { x: 0, y: -10, z: 510 },
				lookAt: { x: 0, y: -25, z: 0 }
			},
			labels: [
				{
					title: 'Elevate Value That Resonates',
					initPosition: { x: 75, y: 35, z: 18 },
					buttonText: 'Connect the Dots',
					imageURL: 'https://s3.envato.com/files/235783482/syda_0242392.jpg',
					text01: "<p>We help your customers discover everything they need to know by quickly guiding them straight to <strong>value that truly resonates</strong> - without distractions. So they can <strong>visualize themselves using it</strong> and jump straight to purchasing.</p>",
					text02: "<p>Stop guessing which value matches which customer. Our choose-your-own-adventure product experiences <strong>empower your customers to find relatable value on their terms</strong> - without distractions.</p>",
					style: 'split'
				},
				{
					title: 'Inspire Excitement & Passion',
					initPosition: { x: -85, y: 15, z: 18 },
					buttonText: 'Spark Joy',
					imageURL: 'https://s3.envato.com/files/235783482/syda_0242392.jpg',
					text01: '<h3>Take engagement to new dimensions</h3><p>Transport your audience into an immersive journey around your product that <strong>inspires genuine excitement and passion through every interaction</strong>.</p><p>Meta proof point: how are you feeling right now?</p>',
					text02: '',
					style: 'checkbox'
				},
				{
					title: 'Empower Yourself',
					initPosition: { x: 85, y: -35, z: 18 },
					buttonText: 'Simplify Your Life',
					imageURL: '/assets/landing_page/value.png',
					text01: "<h3>Relax. You got this!</h3><p>Product video anxiety is real! We are putting <strong>control back in your hands</strong> by making it unbelievably easy and fast for you to build, publish, and scale Interactive Product Experiences just like this one - so you can move the needle with predictability and ease - and the confidence to do it again... and again... and again.</p><p>Meta proof point: This experience took less than 1 day to build and deploy! Tell that to Explainer Video Productions.</p>",
					text02: '',
					style: 'article'
				},
			],
			camStartPosition: 'right',
			camCurrentPosition: 'right',
			loaderFinish: false,
			editMode: false,
			openingComplete: false,
			theaterMode: false
	 	}

	 	const screenWidth = window.innerWidth;
	 	this.isMobile = ( screenWidth < 800 ? true : false );

	 	// IF MOBILE

	 	if (this.isMobile){
			this.state.camLocation = {
				position: { x: 0, y: -90, z: 690 },
				lookAt: { x: 0, y: -25, z: 0 }
			}
			this.state.camStartPosition = "center";
			this.state.camCurrentPosition = 'center';
			this.state.camPositions.center.position.z = 590;
			this.state.camPositions.center.lookAt.y = -25;
			this.state.camPositions.center.position.y = -30;
		}

		this.media = null;
		this.mediaTexture = null;
		this.mediaImageContext = null;

	 	//this.createDevice = this.createDevice.bind(this);
	 	//this.loadObject = this.loadObject.bind(this);

	 	this.windowHalf = new THREE.Vector2( window.innerWidth / 2, window.innerHeight / 2 );
	 	this.mouse = new THREE.Vector2();
		this.mouseDelay = new THREE.Vector2();
		this.mouseDelayTween = null;

		this.minLoadTime = 2000; // LENGTH OF MINIMAL INITIAL LOAD BEFORE OPEN ANIMATION

		this.currentTime = 0;
		this.intervalRewind = null;

        this.stats = new Stats();
		this.stats.showPanel( 0,2 ); // 0: fps, 1: ms, 2: mb, 3+: custom
 		//document.body.appendChild( this.stats.dom );

 		window.addEventListener('message', event => {
	 		if (event.data === 'theater_mode_open'){
		 		this.theaterMode()
	 		}

	 		if (event.data === 'theater_mode_close'){
		 		this.theaterMode( false )
	 		}
	 	});


	}



	/*
	*******************
	    MOUNTING
	*******************
	*/

    async componentDidMount() {

		// START TIMER - DETERMINE LENGTH OF LOAD

		const startLoad = performance.now();

		// DETECT IF LOGO HAS FINISHED PLAYING, REMOVE LOGO

 		this.logoLoader = document.getElementById('logoLoader');

 		if (this.logoLoader){

			this.minLoadTime = this.logoLoader.length * 1000;
			this.logoLoader.addEventListener('ended', (event) => {
				this.setState({ loaderFinish: true });
			});

		}

		// ANIMATED LOADER

		this.animatedLoader = document.getElementById('animatedLoader');

		if (this.animatedLoader){
			this.minLoadTime = 1450;
		}

	    // CREATE WEBGL SCENE

        [ this.scene, this.camera, this.controls ] = createScene( this.mount, this.isMobile );

	    // CREATE RENDERER

        [ this.renderer, this.effectComposer ] = createRenderer( this.mount, this.scene, this.camera, this.isMobile );

	    // CREATE CSS3D SCENE

        [ this.css_scene, this.css_renderer ] = createCSSScene( this.mount, this.scene, this.camera );

	    // CREATE BG
//         [ this.bgPlane,this.bgTexture ] = createBG( this.scene,this.camera );

        // ADD DEVICE

        [ this.mediaMaterial, this.mediaImageContext, this.mediaTexture, this.media, this.object, this.deviceMaterial ]  = await createDevice( this.scene, this.css_scene, this.mediaMaterial, this.isMobile, this.updateProgress);

        // SPHERES

        [ this.sphereLeft, this.sphereRight ] = createSpheres( this.scene, this.state.backgrounds[this.state.background] );

		// END TIMER - DETERMINE LENGTH OF LOAD

		const endLoad = performance.now();
		const loadTime = endLoad - startLoad;

        // POST DEVICE SCRIPTS DEVICE

		if (loadTime > this.minLoadTime){
			this.setState({ loaderFinish: true });
			this.postDeviceLoad();
			this.handleWindowResize();
		}
		else {
			setTimeout( () => {
				this.setState({ loaderFinish: true });
				this.postDeviceLoad();
				this.handleWindowResize();
			}, this.minLoadTime - loadTime);
		}

    }


    postDeviceLoad = () => {

        // CREATE LIGHTS
        [ this.hemiLight,this.pointLight ] = createLights( this.scene , this.state.backgrounds.[this.state.background].skyColor , this.state.backgrounds.[this.state.background].groundColor );

        // ANIMATION
        this.openingAnimation();

        // EDIT MODE ICON
        if (!this.isMobile)
        this.createTheaterIcon();

        // ANIMATION
        this.animate();

        // HANDLE WINDOW RESIZE
        window.addEventListener('resize', this.handleWindowResize);

        // HANDLE MOUSE MOVEMENT
        window.addEventListener( 'mousemove', this.onMouseMove, false );

		setInterval(this.onMouseMoveDelay,200)

    }

    componentWillUnmount() {

	    // RESET THREE

        window.removeEventListener('resize', this.handleWindowResize);
        window.cancelAnimationFrame(this.requestID);

    }



    /*
	*******************
	GLOBAL FUNCTIONS
	*******************
	*/

    handleWindowResize = () => {

        const width = this.mount.clientWidth;
        const height = this.mount.clientHeight;

	    // UPDATE SIZE OF RENDERER

        this.renderer.setSize( width, height );
        this.css_renderer.setSize( width, height );

        // UPDATE CAMERA

        this.camera.aspect = width / height;
        const zoomAspectAdjust = ( this.camera.aspect > 2 ? ( this.camera.aspect - 2 ) * .3 : 0 );
        this.camera.zoom = this.camera.aspect * ( this.isMobile ? 1.2 : .75 - zoomAspectAdjust ) ;

        if (!this.isMobile){
	        if (this.camera.aspect > 2){
	        document.getElementsByClassName("editPointContainer")[0].style.transform="scale("+(1-(zoomAspectAdjust*1.5))+")";
	        document.getElementsByClassName("editPointContainer")[0].style.transformOrigin="center 10%";
	        }
	        else
	        document.getElementsByClassName("editPointContainer")[0].style.transform="";
	    }

        this.camera.updateProjectionMatrix();

        // MOUSE MOVEMENT

        this.windowHalf = new THREE.Vector2( window.innerWidth / 2, window.innerHeight / 2 );

    }

    handleClick = (e) => {

	    // RETURN CAMERA WHEN CLICK OUTSIDE LABEL TITLE

	    if (this.state.globalLabelActive === false || e.target.className === "labelTitle")
	    return;

	    this.updateActiveLabel( false );
    }

    onMouseMove = ( event ) => {

		this.mouse.x = ( event.clientX - this.windowHalf.x ) / this.windowHalf.x;
		this.mouse.y = ( event.clientY - this.windowHalf.y ) / this.windowHalf.y * -1;

	}

	onMouseMoveDelay = () => {

		// this.mouseDelay.x = this.mouse.x;
		// this.mouseDelay.y = this.mouse.y;

		if (this.mouseDelayTween)
		this.mouseDelayTween.stop();

		this.mouseDelayTween = new TWEEN.Tween( this.mouseDelay )
			.to(  this.mouse, 1500 )
			.easing( TWEEN.Easing.Quartic.Out )
			.start();
	}



    /*
	**********************************************
	//   UPDATE STATE - REMOTE FUNCTION CALLBACKS
	***********************************************
	*/

	updateProgress = ( percent ) => {

		this.setState({ progressPct: percent })

	}

	updateActiveLabel = ( label ) => {

		// RETURN CAMERA TO INITIAL STATE

		if (label === false) {

		 	this.setState({ globalLabelActive: false });

		 	if (this.isMobile === false && this.state.globalLabelActive)
		 	document.getElementsByClassName("editPointContainer")[0].style.transitionDelay = ".8s";

		 	// REVEAL ALL LABELS

			let array = [...this.state.labels];
			array.map((label) => (
			    label.labelActive = false
			))

		 	this.setState({ labels: array },() => {

			 	setTimeout(() => {
					let array = [...this.state.labels];
					array.map((label) => (
					    label.labelClose = false
					))
					this.setState({ labels: array });
				},500)
		 	});

			// MOVE CAMERA

		    this.moveCamera( this.state.camPositions.[this.state.camCurrentPosition].position,this.state.camPositions.[this.state.camCurrentPosition].lookAt );

		    // PING WEBFLOW TO UPDATE

		    window.parent.postMessage('label_off'+(this.isMobile ? '_mobile' : ''),"*");

		}

		// UDPATE GLOBAL LABEL

		else {

		 	this.setState({ globalLabelActive: label });

		 	// HIDE ALL OTHER LABELS

		 	 Object.entries(this.state.labels).map(([key, label_others]) => (
			    label_others.labelClose = ( label_others === label ? false : true )
			))

		    label.labelActive = true;

		}

	}


	changeBG = ( color ) => {

		this.setState({ background: color })

        // UPDATE LIGHTS

        const skyColor = this.state.backgrounds.[color].skyColor;
        const groundColor = this.state.backgrounds.[color].groundColor;

        this.hemiLight.groundColor = new THREE.Color( parseInt('0x' + skyColor.replace('#','') ,16) );
        this.hemiLight.color = new THREE.Color( parseInt('0x' + groundColor.replace('#','') ,16) );


        [ this.sphereLeft, this.sphereRight ] = updateSpheres( this.scene, this.state.backgrounds[color], this.sphereLeft, this.sphereRight );

	}

	addLabel = () => {

		const newLabel = {
			title: 'Boom! Your First Touchpoint!',
			initPosition: { x: -74, y: -25, z: 18 },
			buttonText: 'Save This Experience',
			imageURL: '',
			text01: "<h3>Your Value Here</h3><p><strong>Woah! You just created an interactive 3D touchpoint.</strong> Well done!</p><p>This is where you can add rich text and media (images, video, gifs, etc.) to highlight product value in context of your product.</p><p>Go ahead and <strong>customize this experience to your brand</strong> by clicking on the other beacons. It's addicting!</p>",
			text02: "",
			style: 'article'
		}

		let array = [...this.state.labels];
        array.push(newLabel);

        this.setState(state => ({
            labels: array
        }),() => {
		        let allRemoveIcons = document.getElementsByClassName("removeLabel");
	        	allRemoveIcons[allRemoveIcons.length-1].style.opacity=0;
	        setTimeout(() => {
		        let allLabels = document.getElementsByClassName("labelTitle");
	        	allLabels[allLabels.length-1].click();
	        	setTimeout(() => {
	        		allRemoveIcons[allRemoveIcons.length-1].style.opacity=1;
	        	},500)
			},700)}
        );

	}

	changeDeviceColor = ( color ) => {

		let array = this.state.device;
		array.color = color;
		this.setState({ device: array });

		this.object.traverse((child) => {
			if (child.name === "stand") {
		      const newColor = '0x' + this.state.devices.[this.state.device.name].colors.[color].replace('#','');
		      child.material.color.setHex(newColor);
		    }
		});

	}

	removeLabel = ( index ) => {

		// REMOVAL LAST ITEM

		let array = [...this.state.labels];
        array.splice(index, 1);

        this.setState(state => ({
            labels: array
        }));

	}



	changeTitle = () => {

		let array = [...this.state.labels];

		array[1].title='test';

        this.setState(state => ({
            labels: array
        }));

	}



     /* 
	*************************
	   THREEJS FUNCTIONS
	*************************
	*/

	moveCamera = ( targetPosition, targetLookPosition, speed = 1000, customEasing = TWEEN.Easing.Cubic.InOut ) => {

		// MOVE CAMERA TO TARGET (LABEL OR DEFAULT)

	    new TWEEN.Tween( this.state.camLocation.lookAt )
			.to(  targetLookPosition, speed )
			.easing( customEasing )
			.start();

		new TWEEN.Tween( this.state.camLocation.position )
			.to(  targetPosition, speed )
			.easing( customEasing )
			.delay(40)
			.start()

	}

    openingAnimation = () => {

        // PLAY OPENING VIDEO IF THERE'S A VIDEO
        if (!!this.media.play){
			setTimeout(()=>{
				this.playVideo( 'forward' );
			},400)
		}

		// MOVE BACK AND TO CENTER
		setTimeout(()=>{
			this.moveCamera( this.state.camPositions.center.position,this.state.camPositions.center.lookAt, 700, TWEEN.Easing.Cubic.Out );
		}, (this.isMobile ? 0 : 500) )


        // TURN ON LIGHTS

        setTimeout( () => {
	        let start = { x : 0, y: 0 }; let target = { x : 1, y: 1 };
			new TWEEN.Tween( start ).to( target , 900).easing( TWEEN.Easing.Quadratic.InOut ).onUpdate(() => {

			    // TURN UP LIGHTS
			    this.hemiLight.intensity = start.x * 2;
			    this.pointLight.intensity = start.x * 234.6;
			    this.mediaMaterial.reflectivity = start.x * .04;
			    this.mediaMaterial.emissiveIntensity = start.x;
			    this.object.emissiveIntensity = start.x;
			    this.deviceMaterial.envMapIntensity = start.x * 2.4;

			    // TURN OFF BLACK BACKGROUND ON VIEWPORT
				document.getElementById('viewport').style.backgroundColor="rgba(0,0,0,"+(1-(start.x))+")";

				// SHOW SPHERES
				this.sphereRight.material.opacity = start.x;
				this.sphereLeft.material.opacity = start.x;

			})
			.start()
			.onComplete(async () => {

				// MOVE CAMERA

				this.moveCamera( this.state.camPositions.[this.state.camStartPosition].position,this.state.camPositions.[this.state.camStartPosition].lookAt );

				// SWAP MOVIE WITH IMAGE

				if (!this.isMobile)
				[ this.mediaMaterial, this.mediaImageContext, this.mediaTexture, this.media ] = await createMedia( this.mediaMaterial, this.isMobile, this.updateProgress );


			    // PING WEBFLOW TO UPDATE

			    setTimeout( () => {
		    		window.parent.postMessage('animation_complete',"*");

					// SET OPENING COMPLETE STATE
					this.setState({ openingComplete: true })
		    	}, this.isMobile ? 0 : 800 )

			});
		}, (this.isMobile ? 0 : 600) ) // 1000

        // CREATE CSS LABELS NOW THAT CSSSCENE IS CREATED

        setTimeout( () => {

			Object.entries(this.state.labels).map(([key, label]) => {
			    this.css_scene.add( label.labelCSS ).add( label.labelButtonCSS )

				// RESET ANIMATION DELAYS

				const labelContent = document.getElementById('label_'+key).getElementsByClassName('label')[0];
				setTimeout(()=>{
					labelContent.style.opacity = '1';
					labelContent.style.animation = 'none';
					// labelContent.style.animation = 'label-pulse 2s 5s linear infinite';
					labelContent.style.animationDelay = '0s';
				}, 700+(700*key*.2))

				return true;
			})

        }, (this.isMobile ? 0 :700) ) // 2700

	}


    playVideo = ( direction ) => {

	    this.media.pause();

	    // PLAY

	    if ( direction === 'forward' ){
		    clearInterval(this.intervalRewind);
		    this.currentTime = 0;
		    this.media.play();
		    return;
	    }

	    // REWIND

		let fps = 60;
		this.currentTime = this.media.duration;
	    this.intervalRewind = setInterval( () => {
	        if(this.currentTime <= 0){
	           this.currentTime = 0;
	           clearInterval(this.intervalRewind);
	        }
	        else {
				this.currentTime -= ((1/fps) * this.media.duration).toFixed(2);
	        }
	    }, 1000 / fps);

    }





    /*
	*******************
	     EDIT MODE
	*******************
	*/

	editMode = ( status = "toggle" ) => {

		if (status === "toggle")
		status = !this.state.editMode;

		const newCamAngle = ( status ? 'center' : this.state.camStartPosition )

		this.updateActiveLabel( false )

		this.setState({ editMode: status })

	}

	updateScreen = ( uploadURL ) => {

		const runUpdate = async () => {
			[ this.mediaMaterial, this.mediaImageContext, this.mediaTexture, this.media ] = await createMedia( this.mediaMaterial, this.isMobile, null, uploadURL);
		}
		runUpdate();

	}

	createTheaterIcon = () => {

		// ADD CSS SCREEN

	    const editModeIcon = new CSS3DObject( document.getElementById('theaterModeIcon') );
		editModeIcon.position.x = 80;
		editModeIcon.position.y = -60;
		editModeIcon.position.z = 14;
		editModeIcon.scale.set(.1,.1);

		this.css_scene.add( editModeIcon );

		const editModeIconContent = document.getElementById('theaterModeIcon').getElementsByClassName("inner")[0];
		setTimeout(()=>{
			editModeIconContent.style.opacity = '1';
			editModeIconContent.style.animation = 'none';
			editModeIconContent.style.transform = 'scale(.99)';
		}, 2500)

	}

	theaterMode = ( status = true ) => {

		const newCamAngle = ( status ? 'center' : this.state.camStartPosition )

		this.updateActiveLabel( false )

		this.setState({ camCurrentPosition: newCamAngle })
		this.setState({ theaterMode: status })

		const position = this.state.camPositions.[newCamAngle].position;
		const lookAt = this.state.camPositions.[newCamAngle].lookAt;

		// MOVE CAMERA
		this.moveCamera( position,lookAt )

	    // PING WEBFLOW TO UPDATE
	    const message = ( newCamAngle === 'center' ? 'theater_on' : 'theater_off');
	    window.parent.postMessage( message,"*" );

	}

	changeDevice = ( device = 'monitor' ) => {



	}

    /*
	*******************
	       ANIMATE
	*******************
	*/

    animate = () => {

 		TWEEN.update();
	    this.controls.update();

 		this.stats.begin();
 		this.stats.end();

		// LOCK BG TO CAMERA
		if (this.bgPlane){
			this.bgPlane.position.copy( this.camera.position );
			this.bgPlane.rotation.copy( this.camera.rotation );
			this.bgPlane.position.set( 0,0,-500 );
			this.bgPlane.updateMatrix();
		}


		// FOR VIDEO
		const isVideo = !!this.media.play;
		if (isVideo && this.media.readyState === this.media.HAVE_ENOUGH_DATA) {

			if (this.currentTime !== 0){
				let media = this.media;
				media.currentTime = this.currentTime;
				this.setState({media: media});
			}

		  	const canvasWidth = 1024;
			const canvasHeight = 1024;
			this.mediaImageContext.drawImage(this.media, 0, 0, canvasWidth, canvasHeight);
		}

		// UPDATE TEXTURE
		this.mediaTexture.needsUpdate = true;

		// MOVE RELFECTION


		// CAMERA MOVEMENT
		const slideOffset = Math.abs(this.state.camLocation.position.x / 100);
		const camMult = 35 - slideOffset * 2;
		this.camera.position.x = this.state.camLocation.position.x - (!this.isMobile ? this.mouseDelay.x * camMult : 0);
		this.camera.position.y = this.state.camLocation.position.y - (!this.isMobile ? this.mouseDelay.y * camMult : 0);
		this.camera.position.z = this.state.camLocation.position.z;


		this.camera.lookAt(this.state.camLocation.lookAt.x, this.state.camLocation.lookAt.y, 0);

		// Render out scene
        this.renderer.render( this.scene, this.camera );
		// this.effectComposer.render( this.scene, this.camera );
        this.css_renderer.render( this.css_scene, this.camera );

        // Keep updating every frame
         this.requestID = window.requestAnimationFrame(this.animate);
		//  setTimeout( () => {
		// 	this.requestID = window.requestAnimationFrame(this.animate);
		// }, 1000 / (this.isMobile ? 40 : 120 ) );
    };



    /*
	*******************
	      RENDER
	*******************
	*/

    render() {
        return (
	        <>
	        	<div className='testing'>
		        	<button className="mountButton" style={{ top:'105px' }} onClick={() => this.changeDevice('phone')}>
	                    Change Device
	                </button>
	            </div>

	            <div className='testing_data'> </div>

		        <div className={"viewportContainer " + ( this.isMobile ? 'mobile' : '' )  + ( this.state.theaterMode ? ' theaterMode' : '' ) }
		        	style={{backgroundImage: this.state.backgrounds.[this.state.background].bg }}
				>

				{ !this.state.openingComplete && 
				<div className={"animatedLoader "+(this.state.progressPct === 100 && this.state.loaderFinish === true ? 'animatedLoader-complete' : '' )} id="animatedLoader" >
					<span>Make</span> <span>your</span> <span>product</span> <span>shine</span>
					<div className={"animatedLoaderSpinner "+(this.state.progressPct !== 100 && this.state.loaderFinish === true ? 'active' : '' )}>Loading</div>
				</div>
				}	
{/* 
					// PROGRESS BAR
		        	<div className="progress" style={{opacity: (this.state.progressPct === 100 ? 0 : 1 )}}>
		        		<div style={{transform: 'scale('+this.state.progressPct/100+',1)'}} />
		        	</div> 
*/}

{/*
					// LOGO LOADER
		        	<div className="progress_loader" style={{opacity: (this.state.progressPct === 100 && this.state.loaderFinish === true ? 0 : 1 )}}>
		        		<video id="logoLoader" width="70" height="70" autoplay="autoplay" muted>
						  <source src={ process.env.PUBLIC_URL + "/threejs/loaders/logo.mp4"} type="video/mp4" />
						</video>
		        	</div>
*/}

					<div className={"editClose " + ( this.state.editMode || this.state.theaterMode ? 'active' : '' )} onClick={() => { this.editMode( false ); this.theaterMode( false )} } />
					<div className={"editToggle " + ( this.state.editMode ? 'on' : '' ) + ( this.state.theaterMode ? ' visible' : '' )} onClick={() => { this.editMode() } }>
						<div />
					</div>

	                <div id="viewport" className={"viewport " + ( this.state.globalLabelActive ? 'darken' : '' )} style={{backgroundColor: "rgba(0,0,0,1)"}} ref={ref => (this.mount = ref)} onTouchStart={ this.handleClick } onClick={ this.handleClick } />

	                { !this.isMobile &&
		                <EditPoints
		                	globalLabelActive = {this.state.globalLabelActive}
		                	editMode = {this.state.editMode}
		                	backgroundColor = {this.state.background}
		                	backgrounds = {this.state.backgrounds}
				        	changeBG = {this.changeBG}
				        	addLabel = {this.addLabel}
			        		skyColor = {this.state.backgrounds.[this.state.background].skyColor}
			        		groundColor = {this.state.backgrounds.[this.state.background].groundColor}
			        		updateScreen = {this.updateScreen}
			        		device = {this.state.device}
			        		devices = {this.state.devices}
			        		changeDeviceColor = {this.changeDeviceColor}
			        		closeEditMode = {this.editMode}
				        	isMobile = {this.isMobile}
		                />
	                }

	        	</div>

	        	<div style={{ display:'none' }}>

	        		<div id="screen" className={"screen " + ( this.state.editMode && !this.state.globalLabelActive ? 'active' : '' )}>
	        			<Dropzone updateScreen = {this.updateScreen} />
	        		</div>

	        		{ !this.isMobile &&
		        		<div
		        			id="theaterModeIcon"
		        			onClick={() => this.theaterMode()} className={"theaterModeIcon " + ( this.state.theaterMode || this.state.globalLabelActive ? 'close' : '' )}
		        		>
		        			<div className="inner" />
		        		</div>
	        		}

			        {Object.entries(this.state.labels).map(([key, label]) => (
					    <Label
			        		key={key}
			        		keyID={parseInt(key)}
			        		labelData={label}
			        		labels={this.state.labels}
			        		camLocation={this.state.camLocation}
			        		camPositions={this.state.camPositions}
			        		camStartPosition={this.state.camStartPosition}
			        		camCurrentPosition={this.state.camCurrentPosition}
			        		cssScene={ this.css_scene }
			        		playVideo ={ this.playVideo }
			        		isMobile = {this.isMobile}
			        		editMode = {this.state.editMode}
			        		theaterMode = {this.state.theaterMode}
			        		updateActiveLabel = {this.updateActiveLabel}
			        		moveCamera = {this.moveCamera}
			        		skyColor = {this.state.backgrounds.[this.state.background].skyColor}
			        		groundColor = {this.state.backgrounds.[this.state.background].groundColor}
			        		removeLabel = {this.removeLabel}
			        	/>
					))}

		        </div>
	        </>
        )
    }
}






class Viewport extends Component {

	constructor(props){
		super(props)
		this.state = {
			isMounted: true
	 	}
	}

    render() {
        return (
	        <>
	        	<SvgContainer />
	        	<div className='testing'>
		        	<button className="mountButton" onClick={() => this.setState({ isMounted: !this.state.isMounted })}>
	                    {this.state.isMounted ? "Unmount" : "Mount"}
	                </button>
                </div>

                {this.state.isMounted &&
	                <App />
	            }
        	</>
        )
    }
}
export default Viewport;
