import React, { useEffect, useState } from "react";
import {useDropzone} from 'react-dropzone';

export function Dropzone(props) {

  const [files, setFiles] = useState([]);
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
      
      props.updateScreen(acceptedFiles[0].preview);
    }
  });

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
      <div {...getRootProps({className: 'dropzone ' + (isDragActive ? 'active' : '') })}>
        <input {...getInputProps()} />
        { props.label }
      </div>
  );
}

export default Dropzone;