import React, { Component } from "react";
import { convertColor } from '../Misc/Utilities';
import { Dropzone } from '../Misc/Dropzone';

export class EditPoints extends Component {
	  
	constructor(props){
		super(props);
		this.state = {
			activeEditPoint: null,
			editPoints: [
				{
					name: "backgroundColor",
					label: "Change Background",
					position: {
						x: 30,
						y: 4
					},
					quickClick: false,
					colors: [
						'purple',
						'rose',
						'white',
						'dark_grey'
					]
				},
				{
					name: "touchpoint",
					label: "Create Touchpoint",
					position: {
						x: 12,
						y: 50
					},
					quickClick: true,
				},
				{
					name: "deviceColor",
					label: "Change Device Color",
					position: {
						x: 57,
						y: 78
					},
					quickClick: false,
					colors: [
						'silver',
						'grey'
					]
				},
				{
					name: "changeScreen",
					label: "Upload Screen",
					position: {
						x: 60,
						y: 35
					},
					quickClick: true,
				}
			]
		}
	}
	
	handleHover = ( name ) => {
		
	}
	
	handleClick = ( name ) => {
		this.setState({ activeEditPoint: ( this.state.activeEditPoint === name ? null : name ) })
	}
	
	closeEditPoints = ( e ) => {
		
		if (!e.target.className.includes("editPointContainer") && !e.target.className.includes("editPointBoundary"))
		return;
		
		this.setState({ activeEditPoint: null })
	}
	
	handleContent = ( editPoint ) => {
		
		let content = "";
		
		// BACKGROUND COLOR
		
		if ( editPoint.name === 'backgroundColor'){
			content = (
				<ul className="colorSelector">
					{
						editPoint.colors.map((color, index) =>
							<li 
								label={color}
								key={index} 
								onClick={() => this.props.changeBG(color) } className={ color === this.props.backgroundColor ? 'selected' : '' }
								style={{ 
				        	"backgroundImage": "linear-gradient(45deg, rgba("+convertColor(this.props.backgrounds.[color].skyColor)+", 1) 0%, rgba("+convertColor(this.props.backgrounds.[color].groundColor)+", 1) 100%"}}
								/>
						)
					}
				</ul>
			)
		}
		
		// DEVICE COLOR
		
		if ( editPoint.name === 'deviceColor'){
			content = (
				<ul className="colorSelector">
					{
						editPoint.colors.map((color, index) =>
							<li 
								key={index} 
								onClick={() => this.props.changeDeviceColor(color) } className={ color === this.props.device.color ? 'selected' : '' }
								style={{ 
				        	"backgroundImage": "linear-gradient(45deg, rgba("+convertColor(this.props.devices.[this.props.device.name].colors.[color])+", .5) 0%, rgba("+convertColor(this.props.devices.[this.props.device.name].colors.[color])+", 1) 100%"}}
								/>
						)
					}
				</ul>
			)
		}
		
		// TOUCH POINTS
		
		if ( editPoint.name === 'touchpoint'){
			const action = () => { 
				
				// HIDE BEACONS SO DOESNT GLITCH
				document.getElementsByClassName("editPointContainer")[0].classList.remove("active")
				
				const index = 1;
		
				let array = [...this.state.editPoints];
		        array.splice(index, 1);
		
		        this.setState(state => ({
		            editPoints: array
		        }));	
				this.props.addLabel();
		        
		        this.handleClick( null ) 
			}
			content = (
				<div className="editPointQuickButton" onClick={ action } />
			)
		}
		
		// SCREEN
		
		if ( editPoint.name === 'changeScreen'){
			content = (
				<div className="editPointQuickButton">
					{ <Dropzone updateScreen = {this.props.updateScreen} label = "" /> }
				</div>
			)
		}
		
		// RENDER
		
	    return (
		    <> 
			  <div className="editPointTitle">{ editPoint.label }</div>
	      	  <div className={"editPointMain " + editPoint.name}>{ content }</div>
	      	</>
	    );
	}

    render() {
        return (
	        <div 
	        	className={"editPointContainer " + ( this.props.editMode && !this.props.globalLabelActive ? 'active' : '' )}
		        onClick={ this.closeEditPoints }
		        style={{ 
			        'pointerEvents': ( this.state.activeEditPoint ? 'auto' : 'none' )
			    }}
	        >
	        	<div className="editPointBoundary">
			        { Object.entries(this.state.editPoints).map(([key, editPoint]) => (
				        <div 
				        	className={"editPoint " 
					        	+ ( this.state.activeEditPoint && this.state.activeEditPoint !== editPoint.name ? 'hide' : "" )
					        	+ ( this.state.activeEditPoint === editPoint.name ? 'active' : "" )}
				        	key={ editPoint.name } 
				        	style={{ left: editPoint.position.x + "%", top: editPoint.position.y + "%" }}
				        	onMouseEnter={ () => this.handleClick( editPoint.name ) }
				        	onMouseLeave={ () => this.handleClick( null ) }
				        >
				        	<div className="beacon"></div>
				        	
							<div className={"editPointContent " + ( editPoint.quickClick ? 'quickClick' : '')}>
				        		{ this.handleContent( editPoint ) }
				        	</div>
						</div>
					  ))
					}
				</div>
			</div>
	    )
	}
}

export default EditPoints;