import * as THREE from "three";

export function createSpheres ( scene, colors ) {
	
	// CREATE SPHERES
	
	const size = 512;
	
		// TEXTURE 1

		const canvas = document.createElement( 'canvas' );
		canvas.width = size;
		canvas.height = size;
	
		var context = canvas.getContext( '2d' );
	
		context.rect( 0, 0, size, size );
		var gradient = context.createLinearGradient( size/2, 0, size/2, size );
		gradient.addColorStop(0, colors.sphereRight_skyColor); // top 
		gradient.addColorStop(1, colors.sphereRight_groundColor); // bottom
		context.fillStyle = gradient;
		context.fill();
		var texture = new THREE.Texture( canvas );
		texture.encoding = THREE.sRGBEncoding;
		texture.needsUpdate = true;
	
	const sphereMaterial = new THREE.MeshBasicMaterial( {map: texture, opacity: 0, premultipliedAlpha: true } );
	sphereMaterial.needsUpdate = true
	
		// TEXTURE 2
	
		const canvas2 = document.createElement( 'canvas' );
		canvas2.width = size;
		canvas2.height = size;
	
		var context2 = canvas2.getContext( '2d' );
	
		context2.rect( 0, 0, size, size );
		var gradient2 = context2.createLinearGradient( size/2, 0, size/2, size );
		gradient2.addColorStop(0, colors.sphereLeft_skyColor); // light blue 
		gradient2.addColorStop(1, colors.sphereLeft_groundColor); // dark blue
		context2.fillStyle = gradient2;
		context2.fill();
	
		var texture2 = new THREE.Texture( canvas2 );
		texture2.encoding = THREE.sRGBEncoding;
		texture2.needsUpdate = true;
	
	const sphereMaterial2 = new THREE.MeshBasicMaterial( { map: texture2, opacity: 0, premultipliedAlpha: true } );
	sphereMaterial2.needsUpdate = true
	
	// SPHERE 1
	
	const sphereGeometry = new THREE.SphereBufferGeometry( 80, 80, 100 );
	const rightSphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
	rightSphere.position.set(100,30,-200);
    scene.add( rightSphere ); 
	
	// SPHERE 2
	
	const sphereGeometry2 = new THREE.SphereBufferGeometry( 50, 50, 100 );
	const leftSphere = new THREE.Mesh(sphereGeometry2, sphereMaterial2);
	leftSphere.position.set(-200,-50,200);
    scene.add( leftSphere );
	
	return [ leftSphere , rightSphere ];  
    
};


    
 export function updateSpheres ( scene, colors, leftSphere, rightSphere ) {   
    
    // CHANGE COLOR
    
	const size = 512;
  
    var context = rightSphere.material.map.image.getContext( '2d' );
	var gradient = context.createLinearGradient( 0, 0, size, size );
	gradient.addColorStop(0, colors.sphereRight_skyColor); // light blue 
	gradient.addColorStop(1, colors.sphereRight_groundColor); // dark blue
	context.fillStyle = gradient;
	context.fill();
	rightSphere.material.map.needsUpdate = true;

	// var texture = new THREE.Texture( canvas );
	// texture.encoding = THREE.sRGBEncoding;
	// texture.needsUpdate = true;
  
    var context = leftSphere.material.map.image.getContext( '2d' );
	var gradient = context.createLinearGradient( 0, 0, size, size );
	gradient.addColorStop(0, colors.sphereLeft_skyColor); // light blue 
	gradient.addColorStop(1, colors.sphereLeft_groundColor); // dark blue
	context.fillStyle = gradient;
	context.fill();
	leftSphere.material.map.needsUpdate = true;

	return [ leftSphere , rightSphere ];  

};