import * as THREE from "three";
import request from 'superagent';
import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';

// OBJECT LOADER
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
	
	
export function fetchVideoAndUpdatePercent ( media, url, updateProgress ) {
	
	  request
		  .get(url)
		  .responseType('blob')
		  .on('progress', (e) => {
			  if (updateProgress){
				const progressPct = 50 + (e.percent/2);
				updateProgress( progressPct )
			  }
		  })
		  .end((err, res) => {
				if (!err) {
					const blob = res.body;
					media.src = URL.createObjectURL(blob); 
				}
	
				if (err) {
					console.log(err);
				}
		  });
	}
	
export function createMedia ( mediaMaterial, isMobile, updateProgress, uploadURL, renderer ) {
	
		  return new Promise((resolve, reject) => {
			
			// DEFAULT, SHOW IMAGE
			let isImage = true;
			let mediaURL = process.env.PUBLIC_URL + "/threejs/screen/screen2.jpg";
			
			// DESKTOP - SHOW MOVIE
			
			// if (!isMobile && !mediaMaterial){
			// 	isImage = false;
			// 	mediaURL = process.env.PUBLIC_URL + "/threejs/screen/screen2.mp4";
			// }
			
			// MOBILE - SHOW IMAGE
			
			if (isMobile){
				isImage = true;
				mediaURL = process.env.PUBLIC_URL + "/threejs/screen/screen2.jpg";
			}
			
			if (uploadURL){
				isImage = true;
				mediaURL = uploadURL;
			}
	
		    let media = document.createElement(isImage ? 'img' : 'video');
		    media.crossOrigin = "anonymous";
		    
		    if (!isImage){
			    media.muted = true;
			    media.setAttribute('muted', 'muted');
			    media.playsinline = true;
		    }
		    
		    fetchVideoAndUpdatePercent( media, mediaURL , updateProgress);
		   
		    media.addEventListener(isImage ? 'load' : 'loadeddata', () => {
	
				const mediaImage = document.createElement( 'canvas' );
				
				const canvasWidth = 1024;
				const canvasHeight = 1024;
				mediaImage.width = canvasWidth;
				mediaImage.height = canvasHeight;

				const mediaImageContext = mediaImage.getContext( '2d' );
				mediaImageContext.fillStyle = '#000000';
				mediaImageContext.fillRect( 0, 0, canvasWidth, canvasHeight );
				mediaImageContext.drawImage(media, 0, 0, canvasWidth, canvasHeight);
				
				// MEDIA SPEED
				/*
if (!isImage){
					let media = this.state.media;
					media.playbackRate = media.duration / 1; // match speed of camera
					this.setState({media: media})
				}
*/

				const mediaTexture = new THREE.Texture( mediaImage );

				mediaTexture.minFilter = THREE.LinearFilter;
				mediaTexture.magFilter = THREE.LinearFilter;
				mediaTexture.wrapT = THREE.RepeatWrapping;
				mediaTexture.encoding = THREE.sRGBEncoding;
				mediaTexture.repeat.y = -1;
				
				// IF MATERIAL EXISTS, JUST UPDATE IT
				
				if (mediaMaterial){
					mediaMaterial.map = mediaTexture;
					mediaMaterial.emissiveMap = mediaTexture;
					mediaMaterial.needsUpdate = true;
					return resolve([ mediaMaterial , mediaImageContext, mediaTexture, media ]);
				}

				// NEW REFLECTION

				// var svg = document.getElementById("svg_reflection");
				// var svgData = (new XMLSerializer()).serializeToString(svg);

				var canvas = document.createElement("canvas");
				/*
canvas.style.border="#FFF solid 5px";
				canvas.style.position="absolute";
				canvas.style.top="0px";
				canvas.style.left="0px";
				canvas.style.zIndex="10";
*/
				canvas.style.backgroundColor="#000000";
				canvas.width = 4096*2;
				canvas.height = 2048*2;
				var ctx = canvas.getContext("2d");
				var offsetX = 300, offsetY = 0;
				const sizeReduce = 6;
				
				if (isMobile)
				offsetX = 10
				
				ctx.translate(offsetX, offsetY);
					
				var img = document.createElement("img");
				img.setAttribute("src", process.env.PUBLIC_URL + "/threejs/screen/reflection_mini.svg" );
				img.width = 2000*1;
				img.height = 2500*1;

				var envMapScreen = new THREE.Texture(canvas);
				envMapScreen.needsUpdate = true;
				envMapScreen.minFilter = THREE.LinearFilter;
				envMapScreen.magFilter = THREE.LinearFilter;
				envMapScreen.mapping = THREE.EquirectangularReflectionMapping;

				img.onload = function() {
					
					ctx.drawImage(img, canvas.width/2 - img.width/sizeReduce + 1940,canvas.height/2 - img.height/sizeReduce, img.width/(sizeReduce/2) , img.height/(sizeReduce/2));
// 					document.getElementById('root').append(canvas)

					if (isMobile){
						envMapScreen = new THREE.TextureLoader().load(process.env.PUBLIC_URL + "/threejs/screen/reflection_mobile6.svg");
						envMapScreen.needsUpdate = true;
						envMapScreen.minFilter = THREE.LinearFilter;
						envMapScreen.magFilter = THREE.LinearFilter;
						envMapScreen.mapping = THREE.EquirectangularReflectionMapping;
					}

					var mediaMaterial = new THREE.MeshPhongMaterial({
						emissiveMap: mediaTexture,
						emissive:0xffffff,
						emissiveIntensity: 0,
						color:0x000000,
						map: mediaTexture,
						envMap: envMapScreen,
						specular:0x000000,
						shininess:10,
						reflectivity:0, //.12
						side: THREE.DoubleSide,
						combine:THREE.AddOperation,
						transparent:true,
						dithering:true
					});
						
					mediaMaterial.needsUpdate = true;

					return resolve([ mediaMaterial , mediaImageContext, mediaTexture, media ]);

				};
	    	});
	  	});
	
	}