import React from 'react';


const svgContainer = () => (



  <div id="svgContainer" style={{width: '200px', height: '200px', display: 'none'}}>
  
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
    	viewBox="0 0 2899.4 1634.51"
      style={{enableBackground: 'new 0 0 2899.4 1634.51'}}
      xmlSpace="preserve"
      id="svg_reflection"
    >
	<style type="text/css" dangerouslySetInnerHTML={{__html: `
	.st0{fill:url(#Light_1_);}
	.st1{fill:url(#HideSide_1_);}
	.st2{fill:url(#HideBottom_1_);}
	.st3{fill:url(#BigBlob2_1_);}
      `}} />
<g id="Layer_2_1_">
	<g id="Layer_1-2">
      
	<rect y="0" width="2899.4" height="1634.5"/>
	<radialGradient id="Light_1_" cx="-386.2592" cy="692.6943" r="452.1581" gradientTransform="matrix(0.73 0.24 0.39 -1.17 1396.3729 1805.5011)" gradientUnits="userSpaceOnUse">
		<stop  offset="0" style={{stopColor: '#FFFFFF'}}/>
		<stop  offset="0.25" style={{stopColor: '#B3B3B3'}}/>
		<stop  offset="0.52" style={{stopColor: '#676767'}}/>
		<stop  offset="0.74" style={{stopColor: '#2F2F2F'}}/>
		<stop  offset="0.91" style={{stopColor: '#0D0D0D'}}/>
		<stop  offset="1" style={{stopColor: '#000000'}}/>
	</radialGradient>
	
	<path id="Light" className="st0" d="M1716.3,1012.7c-98.2,295.2-326.3,485-509.5,424.1S954.7,1087.1,1052.8,792s326.3-485,509.5-424.1 S1814.5,717.6,1716.3,1012.7z"/>
	<polygon id="Cover" points="1570.3,361.3 1093.1,1577.1 1589.8,1631.2 1934.2,504"/>
	<linearGradient id="HideSide_1_" gradientUnits="userSpaceOnUse" x1="188.5065" y1="4221.6235" x2="756.1265" y2="4221.6235" gradientTransform="matrix(0.77 0 0 -0.77 792.55 4188.5449)">
		<stop  offset="0" style={{stopColor: '#000000'}} />
      	<stop  offset="1" style={{stopColor: '#000000', stopOpacity: '0'}} />
	</linearGradient>
	<rect id="HideSide" y="0" className="st1" width="439.1" height="1232.1"/>
	<linearGradient id="HideBottom_1_" gradientUnits="userSpaceOnUse" x1="9071.3467" y1="4471.8247" x2="9956.3369" y2="4471.8247" gradientTransform="matrix(4.714890e-17 -0.77 -0.77 -4.714890e-17 4898.8149 8487.9199)">
		<stop  offset="0" style={{stopColor: '#000000'}} />
      	<stop  offset="1" style={{stopColor: '#000000', stopOpacity: '0'}} />
	</linearGradient>
	<polygon id="HideBottom" className="st2" points="1973.3,815.8 1973.3,1500.4 937.7,1500.4 937.7,815.8"/>
	<radialGradient id="BigBlob2_1_" cx="1039.7561" cy="2207.8491" r="382.9395" gradientTransform="matrix(0.77 0 0 -1.23 870.4 3873.7346)" gradientUnits="userSpaceOnUse">
		<stop  offset="0" style={{stopColor: '#666666'}}/>
		<stop  offset="0.46" style={{stopColor: '#2F2F2F'}}/>
		<stop  offset="0.81" style={{stopColor: '#0D0D0D'}}/>
		<stop  offset="1" style={{stopColor: '#000000'}}/>
	</radialGradient>
	<ellipse id="BigBlob2" className="st3" cx="1672.6" cy="1158.1" rx="295.7" ry="476.4"/>
	</g>
</g>
	</svg>


  </div>
);

export default svgContainer;
