import * as THREE from "three";
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';

export function createRenderer( mount , scene , camera , isMobile ){
	
	// VARIABLES	    
	
    const width = mount.clientWidth;
    const height = mount.clientHeight;
	    
	var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	
	const options = {
		antialias: ( isSafari ? true : true ),
		pixelRatio: ( isSafari ? window.devicePixelRatio : window.devicePixelRatio )
	}
	
	// RENDERER
    
    const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: options.antialias });
    renderer.setSize( width, height );
    renderer.gammaFactor = 2.2;
	renderer.outputEncoding = THREE.sRGBEncoding;
	renderer.setClearColor(0xffffff, 0);
	renderer.setPixelRatio( options.pixelRatio > 1 ? ( isMobile ? 2 : 1.5 ) : options.pixelRatio ); // options.pixelRatio > 1 ? 1.2 : options.pixelRatio
	renderer.physicallyCorrectLights = 1;
	
	// EFFECT COMPOSER

	const parameters = { minFilter: THREE.LinearFilter, magFilter: THREE.LinearFilter, format: THREE.RGBAFormat, stencilBuffer: false };
	const renderTarget = new THREE.WebGLRenderTarget( width * options.pixelRatio, height * options.pixelRatio, parameters );
	
	const effectComposer = new EffectComposer( renderer , renderTarget );
	effectComposer.setSize( width, height );

	const renderPass = new RenderPass( scene, camera );
	renderPass.renderToScreen = true;
	effectComposer.addPass( renderPass );
    
    // ADD SCENE TO MOUNT
    
    mount.appendChild( renderer.domElement );
    
    return [ renderer, effectComposer ];
    
};
	
export default createRenderer;