export function componentToHex ( c ) {
	  var hex = c.toString(16);
	  return hex.length === 1 ? "0" + hex : hex;
	}
	
export function rgbToHex (r, g, b) {
	  return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
	}
	
export function hexToRgb (hex) {
	  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	  return result ? {
	    r: parseInt(result[1], 16),
	    g: parseInt(result[2], 16),
	    b: parseInt(result[3], 16)
	  } : null;
	}
	
export function convertColor( hexColor ){
		const rgbColor = hexToRgb( hexColor );
        return rgbColor.r + ', ' + rgbColor.g + ', ' + rgbColor.b;
	}
export function getLum (hex) {

	var color = convertColor(hex);
	var rgb = color.split(',');
  
	const brightness = Math.round(((parseInt(rgb[0]) * 299) +
						(parseInt(rgb[1]) * 587) +
						(parseInt(rgb[2]) * 114)) / 1000);
	return brightness;
}