import * as THREE from "three";
import request from 'superagent';
import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';

// OBJECT LOADER

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';

// MEDIA

import { fetchVideoAndUpdatePercent, createMedia } from './createMedia.js';

export function loadObject ( updateProgress ) {
		
		  
	  const manager = new THREE.LoadingManager();
	  
		manager.onStart = (url, itemsLoaded, itemsTotal) => {};
		
		manager.onLoad = () => {};
		
		manager.onProgress = (url, itemsLoaded, itemsTotal) => { 
			
			const progressPct = itemsLoaded / itemsTotal * 50;
			updateProgress( progressPct )
			
		};
		
		manager.onError = function ( url ) {};
		
	  return new Promise((resolve, reject) => {
		  
		const loader = new GLTFLoader( manager ).setPath( process.env.PUBLIC_URL + "/threejs/models/monitor/" );
		
		const dracoLoader = new DRACOLoader();
		dracoLoader.setDecoderPath( process.env.PUBLIC_URL + "/threejs/draco/" );
		loader.setDRACOLoader( dracoLoader );

	    loader.load( "monitor.glb", function ( object ) {
			resolve(object.scene)
		});
		
	  });
	  
}

export async function createDevice ( scene, css_scene, mediaMaterialOrig, isMobile, updateProgress ) {
        
    // LOAD OBJECT - wait for it to load before adding to scene
    
    const object = await loadObject( updateProgress );
    const [ mediaMaterial, mediaImageContext, mediaTexture, media ] = await createMedia( mediaMaterialOrig, isMobile, updateProgress );
    
    let envMap = new THREE.TextureLoader().load(process.env.PUBLIC_URL + "/threejs/env/env.jpg");
	envMap.minFilter = THREE.LinearFilter;
	envMap.magFilter = THREE.LinearFilter;
	envMap.mapping = THREE.EquirectangularReflectionMapping;
    
    var deviceMaterial = new THREE.MeshPhysicalMaterial({
		color:0xFFFFFF,
		envMap:envMap,
		envMapIntensity:0,
		reflectivity:.8, //.12
		roughness:.8,
		metalness:0,
		transparent:true,
		dithering:true
	});
	
    object.traverse((child) => {
        if (child.name.includes('screen') === true){
			child.receiveShadow = true;
		}
		child.castShadow = false;
		
		if (child.name === "screen") {
	      child.material = mediaMaterial;
	    }
	    
	    if (child.name === "stand" || child.name === "main") {
		  let aoMap = child.material.aoMap;
	      child.material = deviceMaterial;
	      child.aoMap = aoMap;
		}
	});
	
    scene.add( object );
    
    // ADD CSS SCREEN
    
    const labelCSS = new CSS3DObject( document.getElementById('screen') );
	labelCSS.position.x = 0;
	labelCSS.position.y = 0;
	labelCSS.position.z = 0;
	labelCSS.scale.set(.1,.1);
	
	css_scene.add( labelCSS );
	
	
	
	return [ mediaMaterial, mediaImageContext, mediaTexture, media, object, deviceMaterial ];  
    
};